export class Storage {
  // Method to set an item in LocalStorage
  static set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Method to get an item from LocalStorage
  static get(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  // Method to remove an item from LocalStorage
  static remove(key) {
    localStorage.removeItem(key);
  }

  // Method to clear all items from LocalStorage
  static clear() {
    localStorage.clear();
  }

  // Method to check if an item exists in LocalStorage
  static has(key) {
    return localStorage.getItem(key) !== null;
  }
}