export default class EventEmitter {
  constructor() {
    this.events = {};
  }

  // Register an event listener
  on(event, listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
    return this; // For chaining
  }

  // Register a one-time event listener
  once(event, listener) {
    const wrapper = (...args) => {
      listener(...args);
      this.off(event, wrapper); // Remove after first call
    };
    this.on(event, wrapper);
    return this;
  }

  // Emit an event, calling all listeners with optional arguments
  emit(event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach(listener => listener(...args));
    }
    return this;
  }

  // Remove an event listener
  off(event, listener) {
    if (!this.events[event]) return this;

    this.events[event] = this.events[event].filter(l => l !== listener);
    return this;
  }
}
