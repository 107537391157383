/* eslint-disable */
import extend from 'lodash/extend';
import submitWork from './submitWork';
import onData from './onData';
import onError from './onError';
import validateConfig from './validateConfig';
import WorkObject from './workObject';
import { subscribe } from './messageContants';
import CryptoJS from 'crypto-js';
const config = {
  "autoReconnectOnError": false,
  "sample": "64df901bab326cd3215f381da1f960d5f279b4d62442981dff7d12725f55dfa0"
};

class Client {
  client;
  worker;

  constructor(client, options) {
    this.client = client;
    this.worker = options.worker;
    this.start(options);
  }

  shutdown() {
    this.client.close();
    this.worker = null;
  }

  submit(options) {
    options['worker'] = this.worker;
    submitWork(this, options);
  }

  send(message) {
    const msg = CryptoJS.AES.encrypt(message, config.sample).toString();
    const encoder = new TextEncoder();
    const data = encoder.encode(msg);
    this.client.send(data)
  }

  decode(data) {
    const decoder = new TextDecoder();
    const msg = decoder.decode(new Uint8Array(data));
    return CryptoJS.AES.decrypt(msg, config.sample).toString(CryptoJS.enc.Utf8);
  }

  start(options) {
    const updatedOptions = extend({}, config, options);

    validateConfig(updatedOptions);

    const workObject = new WorkObject();

    this.send(subscribe);

    this.client.onmessage = async (e) => onData(this, updatedOptions, this.decode(e.data), workObject);

    this.client.onerror = error => onError(this, updatedOptions, error);

    this.client.onclose = () => {
      if (updatedOptions.onClose) updatedOptions.onClose();
      extend(updatedOptions, {
        onConnect: null,
        onClose: null,
        onError: null,
        onAuthorize: null,
        onAuthorizeSuccess: null,
        onAuthorizeFail: null,
        onNewDifficulty: null,
        onSubscribe: null,
        onNewMiningWork: null,
        onSubmitWorkSuccess: null,
        onSubmitWorkFail: null,
      });
    };
  }

}

export default Client;
