import { subscribe } from './messageContants';

export default (client, { port, server, onConnect }) => {
  const msg = { "id": "proxy.connect", "method": "proxy.connect", "params": [server, port] };
  client.send(JSON.stringify(msg) + "\n");
  client.send(subscribe);

  if (onConnect) {
    onConnect();
  }
};
