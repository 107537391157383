/* eslint-disable */
import trim from 'lodash/trim';
import processData from './processData';

export default (client, updatedOptions, data, workObject) => {
  data.split('\n').forEach(jsonDataStr => {
    if (trim(jsonDataStr).length) {
      try {
        processData(client, updatedOptions, JSON.parse(trim(jsonDataStr)), workObject);
      } catch(e) {
        console.error(e.message);
      }
    }
  });
};
