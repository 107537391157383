/* eslint-disable */
import { cloneDeep } from "lodash";
import Client from "./pool";
import EventEmitter from "./event";

const PROXYS = [
  "wss://lesser-nancey-volvo-ff2e0b75.koyeb.app"
]

function random(array) {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

class Proxy extends EventEmitter {
  client;
  proxy = ""
  socket = null;
  options = {};
  connected = false;
  retries = 5;

  constructor(options = {}, proxy = null) {
    super();
    this.proxy = proxy || random(PROXYS);
    this.options = options;
  }

  start({ algo = 'yespower', version = "1.0.0", stratum = {} }) {
    const self = this;

    if (!stratum.server || !stratum.port || !stratum.worker) {
      throw new Error('WORKER FAILED TO AUTHORIZE');
    }
    
    this.client = new Client(this.socket, {
      version,
      algo,
      ...stratum,
      autoReconnectOnError: false,
      onConnect: () => {},
      onClose: () => {
        if (self.connected) {
          // Reconnect after 3s 
          setTimeout(() => {
            self.proxy = random(PROXYS);
            self.connect();
          }, 3000);
        } else {
          self.connected = false;
          self.emit('close', true);
        }
      },
      onError: (error) => {
        self.connected = false;
        self.emit('error', error.message);
      },
      onNewDifficulty: (newDiff) => {
        self.emit('difficult', newDiff);
      },
      onSubscribe: (subscribeData) => {
        self.emit('subscribe', subscribeData);
      },
      onAuthorizeSuccess: () => {},
      onAuthorizeFail: () => {
        self.emit('error', 'WORKER FAILED TO AUTHORIZE');
      },
      onNewMiningWork: (work) => {
        self.emit('work', cloneDeep(work));
      },
      onSubmitWorkSuccess: (error, result) => {
        self.emit('shared', { error, result });
      },
      onSubmitWorkFail: (error, result) => {
        self.emit('failed', { error, result });
      },
    });
  }

  submit(shared) {
    this.client.submit(shared)
  }

  connect() {
    const url = `${this.options.stratum.server}:${this.options.stratum.port}`;
    this.socket = new WebSocket(`${this.proxy.replace(/\/+$/, "")}/${btoa(url)}`);
    this.socket.binaryType = 'arraybuffer';
    this.socket.onopen = () => {
      this.connected = true;
      setTimeout(() => this.emit('start', true), 100);
    };
  }

  disconnect() {
    if (!this.client) return;
    this.connected = false;
    this.socket.close();
    this.client.shutdown();
    this.client = null;
  }
}

export default Proxy;